import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { Link } from "gatsby";
import Columns from "../components/columns";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";
import TryButton from "../components/TryButton";
import { FaList, FaCalendarDay, FaChartLine, FaUsers, FaBullhorn, FaInfo, FaCogs } from "react-icons/fa";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import Container from "../components/container";
import Lightbox from "../components/lightBox";
import styles from "./index.module.css";
import Tile from "../components/Tile";




export const query = graphql`
  query {
   zdjecie6: file(relativePath: { eq: "szansa-sprzedazy-nowy.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     zdjecie5: file(relativePath: { eq: "wspoldzielenie-kalendarza.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
           }
      }
    }
     zdjecie4: file(relativePath: { eq: "karta-kontrahenta-zrzut.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
          }
      }
    }
     zdjecie3: file(relativePath: { eq: "pulpit-nowy.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
       
        }
      }
    }
  }
`;

const SystemCRM = ({ data }) => {
  return (
    <FunctionPageLayout title="CRM dla małej firmy" headline="Szukasz systemu CRM dla przedsiębiorstwa do 10 pracowników?">
      <SEO
        title="Prosty CRM dla małej firmy"
        description=" System CRM dla małej firmy ✅ Szanse sprzedaży • Koordynacja pracy • Już od 4zł/mies. • Wypróbuj za darmo!"
      />
    <center> <h2> Zarządzasz małą firmą i potrzebujesz prostego systemu CRM? </h2> </center>
    <br />
    <br />
    <Columns>
    <div>

     <p>Odpowiadając na potrzeby przedsiębiorców stworzyliśmy 
     prosty <strong>CRM dla małej firmy</strong>. Quick CRM skierowany jest do małych zespołów, 
które na tym etapie rozwoju potrzebują <strong> prostych i gotowych rozwiązań</strong>.  </p>

<p><strong>Sprawdzony i prosty w obsłudze CRM</strong> nie przytłoczy użytkowników nadmiarem
funkcji. Świadomie zaprojektowane rozwiązanie pozwala zachować intuicyjność oraz możliwość dopasowania do procesów sprzedażowych
firmy. </p>

<p>Wybierając CRM dla małej firmy, warto wziąć pod uwagę fakt, że <strong>biznes się zmienia</strong>. Twoje przedsiębiorstwo także.
Sprawdź, czy w przyszłości dostawca <strong>może zaoferować Ci dodatkowe możliwości lub nawet dedykowane rozwiązanie, które sprosta nowym potrzebom</strong> - bez skomplikowanej migracji danych.</p>
</div>
<div>
<Lightbox images={[data.zdjecie3]} 
      alts={["Planowanie zadań w kalendarzu w systemie CRM"]}/>
</div>
</Columns>
<br />
<br />
<center><h2> Najważniejsze funkcje prostego systemu CRM </h2></center>
<br />
<br />


  <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap"
          }}
        >
          <Tile to="/sprzedaz-w-crm/">
            <FaList size="70px" />
            <h3>Szanse 
            <br />sprzedaży</h3>
          </Tile>
          <Tile to="/kalendarz-i-zadania/">
            <FaCalendarDay size="70px" />
            <h3>Koordynacja 
            <br />pracy</h3>
          </Tile>
          <Tile to="/raporty/">
            <FaChartLine size="70px" />
            <h3>Analiza <br />sprzedaży</h3>
          </Tile>
          <Tile to="/komunikator/">
            <FaBullhorn size="70px" />
            <h3>
              Sprawna komunikacja
            </h3>
          </Tile>
        </div>
      </Container>
        <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexWrap: "wrap"
          }}
        >
          <Tile to="/kartoteki-kontrahentow/">
            <FaUsers size="70px" />
            <h3>Kartoteki
            <br />kontrahentów</h3>
          </Tile>
          <Tile to="/pulpit-pracownika/">
            <FaInfo size="70px" />
            <h3>Dynamiczne 
            <br />pulpity</h3>
          </Tile>
          <Tile to="/zarzadzanie-uprawnieniami/">
            <FaCogs size="70px" />
            <h3>Zarządzanie< br/> uprawnieniami</h3>
          </Tile>
        </div>
      </Container>
      


<br />
<br />
<center><h2> Obszary, które wspiera CRM dla małej firmy </h2> </center>
<br />
<br / >
<br />
<Columns>
<div>
<h3> Zarządzanie szansami sprzedaży </h3>
<p>Elastyczne kampanie sprzedażowe to narzędzie stworzone z myślą o&nbsp;handlowcach. <strong>W jednym widoku zebrano
najważniejsze informacje o&nbsp;wszystkich szansach sprzedaży</strong>. Wystarczy otworzyć widok, aby w kilka sekund
dowiedzieć się np. z jakimi szansami handlowiec powinien skontaktować się w pierwszej kolejności, jaka jest ich liczba i wartość na danym etapie, czy handlowcy na bieżąco planują i kontaktują się z klientami.
</p>
</div>
<div>
<Lightbox images={[data.zdjecie6]} 
      alts={["zarządzanie szansami sprzedaży"]}/>


</div>
</Columns>
<br />
<br />
<Columns>
<div>
<Lightbox images={[data.zdjecie4]} 
      alts={["baza wiedzy o klientach"]}/>
      

</div>
<div>
<h3> Baza wiedzy o klientach </h3>
<p>Zbiór najpotrzebniejszych informacji o Twoich klientach w jednym miejscu.
Znajdują się tutaj podstawowe informacje o firmie, dane kontaktowe, a także <strong> powiązane szanse sprzedaży i kompletna historia kontaktów z&nbsp;kontrahentem</strong>.
Dane są zawsze dostępne i bezpieczne w systemie CRM. Tak zebrane informacje pozwalają na szybkie zorientowanie się w sytuacji klienta, także osobie, która nie miała wcześniej kontaktu z danym kontrahentem. 
</p>
</div>
</Columns>
<br />
<br />
<br />
<Columns>
<div>
<h3> Koordynacja pracy </h3>
<p> QuickCRM wspiera kierownika w planowaniu pracy zespołu poprzez współdzielone kalendarze, które
<strong> w szybki sposób pozwalają planować spotkania i kontakty</strong>. Każdy z pracowników ma także 
dostęp do zadań w&nbsp;formie listy, która znajduje się na ich pulpicie. Opcja przypominania zadań nie pozwoli, aby ważne spotkania umknęły w natłoku pracy.
</p>
</div>
<div>

<Lightbox images={[data.zdjecie5]} 
      alts={["koordynacja pracy w systemie crm"]}/>
     
     
</div>
</Columns>
<br />
<br />
<br />



   

    </FunctionPageLayout>
  );
};

export default SystemCRM;
